import { axiosInstance } from 'shared/api/axios.defaults';
import { SignInData } from 'shared/typings';

export class AuthApi {
  static async signIn(params: SignInData): Promise<any> {
    const { data } = await axiosInstance.post('/auth/login', params);
    return data;
  }

  static async signOut() {
    return axiosInstance.post('/auth/logout', {
      accessToken: sessionStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    });
  }
}
