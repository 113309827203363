import { faFolder } from '@fortawesome/free-solid-svg-icons';

import { OrdersTable } from 'pages/orders-page/ui';

import { OrderApi } from 'entities/order';

import { PageHeading, PageWrapper } from 'shared/ui';

export const OrdersPage = () => {
  return (
    <PageWrapper>
      <div className="flex justify-between">
        <PageHeading
          icon={faFolder}
          title="Заказы"
          refetchQueryFilters={[OrderApi.KEY, 'getOrders']}
        />
      </div>
      <OrdersTable />
    </PageWrapper>
  );
};
