import { Merchant } from 'entities/merchant/model';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableResponse } from 'shared/typings';

export class MerchantApi {
  static readonly KEY = 'merchant';

  static async create(params: Merchant & { partner_id: number }): Promise<{
    errorMessage: string;
    public_key: string;
    private_key: string;
  }> {
    const response = await axiosInstance.post(`/merchants/create`, params);
    return response.data;
  }

  static async update(params: Partial<Merchant>): Promise<void> {
    const response = await axiosInstance.post(`/merchants/update`, params);
    return response.data;
  }

  static async createFromCSV(params: any): Promise<Blob> {
    const formData = new FormData();
    formData.append('file', params.file[0]);

    const response = await axiosInstance.post(
      '/merchants/create-from-csv',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      },
    );
    return response.data;
  }

  static async getMerchants(params: {
    page: number;
    size: number;
    search: string;
  }): Promise<TableResponse<Merchant>> {
    const { data } = await axiosInstance.get(
      `/merchants?page=${params.page}&size=${params.size}`,
    );
    return data;
  }

  static async getFlows(): Promise<string[]> {
    const { data } = await axiosInstance.get(`/references/order-flows`);
    return data;
  }
}
