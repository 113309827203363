import { useQuery } from '@tanstack/react-query';

import { PartnerApi } from 'entities/partner';

export const usePartnersList = () => {
  const { data } = useQuery({
    queryKey: [PartnerApi.KEY, 'getPartners'],
    queryFn: PartnerApi.getPartners,
  });

  return data
    ? data.content.reduce((prev, next) => {
        if (!prev[next.id]) prev[next.id] = next.name;
        return prev;
      }, {} as { [key: string]: string })
    : {};
};
