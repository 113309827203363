import { useQuery } from '@tanstack/react-query';
import type { SetFieldValue } from 'react-hook-form';

import { MerchantApi } from 'entities/merchant';

import { SimpleSelect, Spinner } from 'shared/ui';

export const SelectOrderFlow = ({
  setValue,
  defaultValue,
}: {
  setValue: SetFieldValue<any>;
  defaultValue?: string;
}) => {
  const { data } = useQuery({
    queryKey: [MerchantApi.KEY, 'getFlows'],
    queryFn: MerchantApi.getFlows,
    staleTime: Infinity,
  });

  if (!data) return <Spinner />;

  const items = data.map((item) => ({
    id: item,
    label: item,
  }));

  const updatedDefaultValue = items.find((item) => item.label === defaultValue);

  setValue(
    'order_flow',
    updatedDefaultValue ? updatedDefaultValue.id : items[0].id,
  );

  return (
    <SimpleSelect
      defaultValue={updatedDefaultValue}
      items={items}
      onChange={(event) => setValue('order_flow', event.id)}
    />
  );
};
