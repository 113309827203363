import './styles.scss';
import { cx } from '@emotion/css';

import { SidebarNav } from './ui';

type SidebarProps = {
  isSmallSidebar: boolean;
};

export const Sidebar = ({ isSmallSidebar }: SidebarProps) => {
  return (
    <aside
      className={cx(
        'sidebar px-2 flex flex-col gap-4  divide-y divide-brand-1/30',
        {
          sidebar__small: isSmallSidebar,
        },
      )}
    >
      <SidebarNav />
    </aside>
  );
};
