import { faFolder } from '@fortawesome/free-solid-svg-icons';

import { RequestsTable } from 'pages/requests-page/ui';

import { RequestApi } from 'entities/request/Request.api';

import { PageHeading, PageWrapper } from 'shared/ui';

export const RequestsPage = () => {
  return (
    <PageWrapper>
      <div className="flex justify-between">
        <PageHeading
          icon={faFolder}
          title="Заявки"
          refetchQueryFilters={[RequestApi.KEY, 'getRequests']}
        />
      </div>
      <RequestsTable />
    </PageWrapper>
  );
};
