import { cx } from '@emotion/css';

import { Fragment, useState } from 'react';

import { EditMerchantDialog } from 'features/edit-merchant/ui';

import type { Merchant } from 'entities/merchant';
import { MerchantApi } from 'entities/merchant';

import { usePartnersList } from 'shared/hooks';
import type { AccessorKey } from 'shared/typings';
import { CopyButton, Table } from 'shared/ui';

export const MerchantsTable = () => {
  const partners = usePartnersList();

  const [editMerchant, setEditMerchant] = useState<Merchant | null>(null);
  const [copiedValue, setCopiedValue] = useState('');

  const columns: AccessorKey<Merchant>[] = [
    { accessorKey: 'name' },
    { accessorKey: 'description' },
    { accessorKey: 'partner_id' },
    { accessorKey: 'order_flow' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="w-[50px] whitespace-nowrap px-6 py-3 text-center"
      >
        статус
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        название
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        описание
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        партнер
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        тип
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        приватный ключ
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        публичный ключ
      </th>
    </tr>
  );

  const renderRow = (merchant: Merchant) => {
    return (
      <tr
        key={merchant.id}
        className="cursor-pointer border-gray-700 bg-white hover:bg-brand-1/20 [&:not(:last-child)]:border-b"
        onClick={() => setEditMerchant(merchant)}
        title="Редактировать"
      >
        <td className="px-6 py-2 text-center">
          <span
            className={cx(
              'm-auto size-[6px] rounded-full flex-center',
              merchant.active ? ' bg-green-600' : ' bg-rose-600',
            )}
          />
        </td>
        <td className="px-6 py-2">{merchant.name}</td>
        <td className="px-6 py-2">{merchant.description}</td>
        <td className="px-6 py-2">{partners[merchant.partner_id]}</td>
        <td className="px-6 py-2">{merchant.order_flow}</td>
        <td className="px-6 py-2">
          <span
            className="flex items-center gap-1"
            onClick={(event) => event.stopPropagation()}
          >
            <CopyButton
              value={merchant.private_key}
              copiedValue={copiedValue}
              setCopiedValue={setCopiedValue}
            />
          </span>
        </td>
        <td className="px-6 py-2">
          <span
            className="flex items-center gap-1"
            onClick={(event) => event.stopPropagation()}
          >
            <CopyButton
              value={merchant.public_key}
              copiedValue={copiedValue}
              setCopiedValue={setCopiedValue}
            />
          </span>
        </td>
      </tr>
    );
  };

  return (
    <Fragment>
      <Table
        queryKeys={[MerchantApi.KEY, 'getMerchants']}
        queryFn={MerchantApi.getMerchants}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {!!editMerchant && (
        <EditMerchantDialog
          merchant={editMerchant}
          onClose={() => setEditMerchant(null)}
        />
      )}
    </Fragment>
  );
};
