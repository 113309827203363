import { Button, DialogTitle } from '@headlessui/react';
import { useState } from 'react';

import { CopyButton, DialogSimple } from 'shared/ui';
import type { DialogSimpleProps } from 'shared/ui';

type AddMerchantDialogConfirmProps = {
  onContinue?: () => void;
  keysData: {
    publicKey: string;
    privateKey: string;
  };
} & DialogSimpleProps;

export const AddMerchantDialogConfirm = ({
  isOpen,
  keysData,
  onContinue,
}: AddMerchantDialogConfirmProps) => {
  const [copiedValue, setCopiedValue] = useState('');

  return (
    <DialogSimple isOpen={isOpen} onClose={console.log}>
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        Вы уверены, что хотите продолжить?
      </DialogTitle>
      <div className="mt-2">
        <p className="text-sm text-white/50">
          Убедитесь, что сохранили себе ключи
        </p>
        <p className="flex h-8 items-center gap-2 text-sm text-white">
          <span>Публичный ключ магазина</span>
          <CopyButton
            value={keysData.publicKey}
            copiedValue={copiedValue}
            setCopiedValue={setCopiedValue}
          />
        </p>
        <p className="flex h-8 items-center gap-2 text-sm text-white">
          <span>Приватный ключ магазина</span>
          <CopyButton
            value={keysData.privateKey}
            copiedValue={copiedValue}
            setCopiedValue={setCopiedValue}
          />
        </p>
      </div>
      <div className="mt-4 flex gap-2">
        <Button
          className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
          onClick={onContinue}
        >
          Я сохранил ключи
        </Button>
      </div>
    </DialogSimple>
  );
};
