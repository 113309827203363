import { cx } from '@emotion/css';

import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@headlessui/react';
import { useState } from 'react';

type DropdownItem = {
  id: string;
  label: string;
};

const updateSetItems = (item: string, checkedItems: Set<string>) => {
  if (checkedItems.has(item)) {
    checkedItems.delete(item);
    return checkedItems;
  }

  checkedItems.add(item);
  return checkedItems;
};

export const InputCheckbox = ({
  items,
  onChange,
  defaultValues,
}: {
  items: DropdownItem[];
  onChange: (item: string[]) => void;
  defaultValues?: any[];
}) => {
  const checkedItems = new Set<string>();

  const _onChange = (item: DropdownItem) => {
    updateSetItems(item.id, checkedItems);
    onChange([...checkedItems]);
  };

  return (
    <div className="mx-auto flex w-full flex-col gap-1">
      {items.map((item) => (
        <SimpleCheckbox
          key={item.id}
          item={item}
          onChange={_onChange}
          defaultChecked={defaultValues && defaultValues.includes(item.id)}
        />
      ))}
    </div>
  );
};

const SimpleCheckbox = ({
  item,
  onChange,
  defaultChecked,
}: {
  item: DropdownItem;
  onChange: (item: DropdownItem) => void;
  defaultChecked?: boolean;
}) => {
  const [checked, setChecked] = useState(defaultChecked ?? false);
  const _onChange = () => {
    onChange(item);
    setChecked(!checked);
  };

  return (
    <div
      onClick={_onChange}
      className={cx(
        'flex items-center gap-1 h-[34px] cursor-pointer w-full rounded-lg border-none bg-white/5 px-3 text-sm/6 text-white',
        'focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white [checked]:bg-white/10',
      )}
    >
      <Checkbox
        checked={checked}
        onChange={setChecked}
        className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-inset ring-white/15 flex-center data-[checked]:bg-white"
      >
        <FontAwesomeIcon
          icon={faCheck}
          className="hidden text-black group-data-[checked]:block"
        />
      </Checkbox>
      <p className="pl-2 font-semibold">{item.label}</p>
    </div>
  );
};
