import { createContext, useContext } from 'react';

import type { User } from 'entities/user';

type UserContextProps = Partial<User> | null;

export const UserContext = createContext<UserContextProps>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContext API Error');
  }

  return context;
};
