import { faHandshake } from '@fortawesome/free-solid-svg-icons';

import { useState } from 'react';

import { PartnersTable } from 'pages/partners-page/ui';

import { AddPartnerDialog } from 'features/add-partner/ui';

import { PartnerApi } from 'entities/partner';

import { ButtonPlusIcon, PageHeading, PageWrapper } from 'shared/ui';

export const PartnersPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PageWrapper>
        <div className="flex justify-between">
          <PageHeading
            icon={faHandshake}
            title="Партнеры"
            refetchQueryFilters={[PartnerApi.KEY, 'getPartners']}
          />
          <div className="flex justify-end gap-2 py-2">
            <ButtonPlusIcon
              text="Добавить партнера"
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
        <PartnersTable />
      </PageWrapper>
      <AddPartnerDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
