import { cx } from '@emotion/css';

import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { useState } from 'react';

export type SelectItem = {
  label: string;
  id: string;
};

export const SimpleSelect = ({
  items,
  onChange,
  dark,
  classNames,
  defaultValue,
}: {
  items: SelectItem[];
  defaultValue?: SelectItem;
  dark?: boolean;
  classNames?: string;
  onChange?: (item: { [key: string]: string }) => void;
}) => {
  const [selected, setSelected] = useState(defaultValue ?? items[0]);

  const _onChange = (item: typeof selected) => {
    setSelected(item);
    onChange?.(item);
  };

  return (
    <Listbox value={selected} onChange={_onChange}>
      <ListboxButton
        className={cx(
          'relative block w-full rounded-lg bg-white/5 py-1.5 pr-8 pl-3 text-left text-sm/6',
          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
          dark ? 'text-brand-1 bg-brand-1/5' : 'text-white bg-white/5',
          classNames,
        )}
      >
        {selected.label}
        <FontAwesomeIcon
          icon={faChevronDown}
          className="group pointer-events-none absolute right-2.5 top-2.5 size-4 text-brand-2"
          aria-hidden="true"
        />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={cx(
          'w-[var(--button-width)] mt-1 fixed z-[10] rounded-xl bg-brand-1/50 backdrop-blur-2xl border border-white/5 p-1 focus:outline-none',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
          dark,
        )}
      >
        {items.map((item) => (
          <ListboxOption
            key={item.label}
            value={item}
            className="group flex w-full cursor-pointer select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
          >
            <FontAwesomeIcon
              icon={faCheck}
              className="invisible size-4 text-brand-2 group-data-[selected]:visible"
            />
            <div className="text-sm/6 text-white">{item.label}</div>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
