import './styles.scss';
import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { AxiosError } from 'axios';
import { UUID } from 'uuid-generator-ts';
import * as yup from 'yup';

import { Field, Input, Label } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthApi } from 'shared/api';
import { XSessionIdKey } from 'shared/contants';
import { useAuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';
import type { SignInData } from 'shared/typings';
import { Button } from 'shared/ui';

const schema = yup.object({
  username: yup.string().lowercase().trim().min(4).required(),
  password: yup.string().trim().min(4).required(),
});

export const AuthPage = () => {
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const signInMutation = useMutation({
    mutationFn: (data: SignInData) => AuthApi.signIn(data),
    onSuccess: (data) => {
      setIsAuth(true);
      navigate(RoutesPath.MERCHANTS);

      sessionStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
    },
    // @ts-ignore
    onError: (error: AxiosError) => {
      console.error('Sign in error:', error);
      const isRefreshExists = !!localStorage.getItem('refreshToken');
      if (error?.response?.status === 401) {
        !isRefreshExists && localStorage.removeItem('refreshToken');
        // @ts-ignore
        toast.error(error?.response?.data.errorMessage);
        return;
      }

      // @ts-ignore
      toast.error(error?.response?.data.errorMessage);
    },
  });

  const onSubmit = async (data: SignInData) => {
    sessionStorage.setItem(XSessionIdKey, UUID.createUUID());
    signInMutation.mutate(data);
  };

  return (
    <form
      className="auth-form animate__animated animate__fadeIn bg-brand-1"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="auth-form__header form-header">
        <p className="form-header__header">Войти</p>
      </div>
      <div className="auth-form__body form-body">
        <Field className="flex w-full flex-col gap-2">
          <Label className="text-sm/6 font-medium text-white">Логин</Label>
          <Input
            {...register('username')}
            defaultValue="admin"
            placeholder="Введите данные"
            className={cx(
              'flex items-center rounded-lg border border-white/5 bg-white/5 py-1.5 px-3 text-sm/6 text-white h-12 w-full',
              'focus:outline-none hover:border-white/50',
            )}
          />
        </Field>
        <Field className="flex w-full flex-col gap-2">
          <Label className="text-sm/6 font-medium text-white">Пароль</Label>
          <Input
            {...register('password')}
            type="password"
            defaultValue="TuTSZqgt"
            placeholder="Введите данные"
            className={cx(
              'flex items-center rounded-lg border border-white/5 bg-white/5 py-1.5 px-3 text-sm/6 text-white h-12 w-full',
              'focus:outline-none hover:border-white/50',
            )}
          />
        </Field>
        <Button
          className=" grid h-12 w-fit items-center justify-self-end px-0 text-white/50"
          onClick={(event) => {
            event.preventDefault();
            toast.info(
              'Для восстановления доступа обратитесь к администратору',
            );
          }}
        >
          Забыл пароль
        </Button>
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="auth-form__error">
              {message}
            </span>
          ))}
      </div>
      <div className="auth-form__footer form-actions">
        <Button
          className=" grid h-12 w-fit items-center justify-self-center px-0 text-xl text-white"
          text="Войти"
          disabled={signInMutation.isPending}
        />
      </div>
    </form>
  );
};
