import { cx } from '@emotion/css';

import { useState } from 'react';

import { EditProductDialog } from 'features/edit-product/ui';

import { Product, ProductApi } from 'entities/product';

import { usePartnersList } from 'shared/hooks';
import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

export const ProductTable = () => {
  const partners = usePartnersList();

  const [editProduct, setEditProduct] = useState<Product | false>(false);

  const columns: AccessorKey<Product>[] = [
    { accessorKey: 'name' },
    { accessorKey: 'partner_name' },
    { accessorKey: 'partner_id' },
    { accessorKey: 'min_limit' },
    { accessorKey: 'max_limit' },
    { accessorKey: 'period_unit' },
    { accessorKey: 'period' },
    { accessorKey: 'total_payments' },
    { accessorKey: 'initial_payment_percent' },
    { accessorKey: 'active' },
    { accessorKey: 'date_beg' },
    { accessorKey: 'date_end' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="w-[50px] whitespace-nowrap  px-6 py-3 text-center"
      >
        статус
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        название
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        партнер
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        лимиты
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        периодичность
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        всего платежей
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        срок действия
      </th>
    </tr>
  );

  const renderRow = (product: Product) => {
    return (
      <tr
        className="cursor-pointer border-b border-gray-700 bg-white hover:bg-brand-1/20"
        key={product.id}
        onClick={() => setEditProduct(product)}
      >
        <td className="px-6 py-4 text-center">
          <span
            className={cx(
              'flex-center m-auto h-[6px] w-[6px] rounded-full',
              !product.active ? 'bg-rose-600' : 'bg-green-600',
            )}
          />
        </td>
        <td className="px-6 py-4 ">{product.name}</td>
        <td className="px-6 py-4 ">{partners[product.partner_id]}</td>
        <td className="px-6 py-4 ">
          {product.min_limit} - {product.max_limit} руб.
        </td>
        <td className="px-6 py-4 ">
          {product.period_unit === 'DAYS'
            ? `каждый ${product.period} день`
            : `каждый ${product.period} месяц`}
        </td>
        <td className="px-6 py-4 ">{product.total_payments}</td>
        <td className="px-6 py-4 ">
          {product.date_beg} - {product.date_end ?? 'не указано'}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table
        queryKeys={[ProductApi.KEY, 'getProducts']}
        queryFn={ProductApi.getProducts}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {editProduct && (
        <EditProductDialog
          product={editProduct}
          onClose={() => setEditProduct(false)}
        />
      )}
    </>
  );
};
