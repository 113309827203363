import type { SetFieldValue } from 'react-hook-form';

import { SimpleSelect } from 'shared/ui';

type StatusSelectProps = {
  valueKey?: string;
  defaultActive?: boolean;
  setValue: SetFieldValue<any>;
};

export const StatusSelect = ({
  valueKey,
  setValue,
  defaultActive,
}: StatusSelectProps) => {
  return (
    <SimpleSelect
      items={
        defaultActive
          ? [
              { id: '1', label: 'Активен' },
              { id: '0', label: 'Не активен' },
            ]
          : [
              { id: '0', label: 'Не активен' },
              { id: '1', label: 'Активен' },
            ]
      }
      onChange={(event) => setValue(valueKey, event.id)}
    />
  );
};
