import { faShop, faUpload } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useState } from 'react';

import { MerchantsTable } from 'pages/merchant-page/ui';

import {
  AddMerchantCsvDialog,
  AddMerchantDialog,
} from 'features/add-merchant/ui';

import { MerchantApi } from 'entities/merchant';

import { Button, ButtonPlusIcon, PageHeading, PageWrapper } from 'shared/ui';

export const MerchantPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCsv, setIsOpenCsv] = useState(false);

  return (
    <Fragment>
      <PageWrapper>
        <div className="flex h-fit justify-between">
          <PageHeading
            icon={faShop}
            title="Магазины"
            refetchQueryFilters={[MerchantApi.KEY, 'getMerchants']}
          />
          <div className="flex justify-end gap-2 py-2">
            <ButtonPlusIcon
              text="Добавить магазин"
              onClick={() => setIsOpen(true)}
            />
            <Button
              className="rounded bg-brand-2 px-2 text-white flex-center"
              onClick={() => setIsOpenCsv(true)}
            >
              <FontAwesomeIcon
                icon={faUpload}
                className="size-4 pr-2 text-white"
              />
              Загрузить из файла
            </Button>
          </div>
        </div>
        <MerchantsTable />
      </PageWrapper>
      <AddMerchantDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <AddMerchantCsvDialog
        isOpen={isOpenCsv}
        onClose={() => setIsOpenCsv(false)}
      />
    </Fragment>
  );
};
