import { cx } from '@emotion/css';

import { useState } from 'react';

import { EditPartnerDialog } from 'features/edit-partner/ui';

import type { Partner } from 'entities/partner';
import { PartnerApi } from 'entities/partner';

import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

export const PartnersTable = () => {
  const [editPartner, setEditPartner] = useState<Partner | false>(false);

  const columns: AccessorKey<Partner>[] = [
    { accessorKey: 'name' },
    { accessorKey: 'description' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="w-[50px] whitespace-nowrap px-6  py-3 text-center"
      >
        статус
      </th>
      <th scope="col" className="whitespace-nowrap px-6 py-3  text-center ">
        название
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 text-center">
        описание
      </th>
    </tr>
  );

  const renderRow = (partner: Partner) => {
    return (
      <tr
        key={partner.name}
        onClick={() => setEditPartner(partner)}
        className="cursor-pointer border-b border-gray-700 bg-white hover:bg-brand-1/20"
      >
        <td className="px-6 py-4 text-center">
          <span
            className={cx(
              'flex-center m-auto h-[6px] w-[6px] rounded-full bg-green-600',
            )}
          />
        </td>
        <td className="px-6 py-4 text-center">{partner.name}</td>
        <td className="px-6 py-4 text-center">{partner.description}</td>
      </tr>
    );
  };

  return (
    <>
      <Table
        queryKeys={[PartnerApi.KEY, 'getPartners']}
        queryFn={PartnerApi.getPartners}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {editPartner && (
        <EditPartnerDialog
          partner={editPartner}
          onClose={() => setEditPartner(false)}
        />
      )}
    </>
  );
};
