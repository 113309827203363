import { HttpStatusCode } from 'axios';

import { axiosInstance } from 'shared/api/axios.defaults';
import { delay } from 'shared/lib';

import type { User } from './model';

export class UserApi {
  static readonly KEY = 'user';

  static async getUsers(): Promise<{
    data: Partial<User>;
    success: boolean;
    error: Error | null;
    errorMessage: Error['message'] | null;
    code: HttpStatusCode | null;
  }> {
    const { data } = await axiosInstance.get(`/users`);
    return data;
  }

  static async getPeopleList(): Promise<{
    success: boolean;
    error: Error | null;
    errorMessage: Error['message'] | null;
    code: HttpStatusCode | null;
    data: {
      count: number;
      items: Partial<User>[];
    };
  }> {
    // @ts-ignore
    return getPeopleListMock();
  }

  static async create(params: User): Promise<{
    errorMessage: Error['message'] | null;
  }> {
    return await axiosInstance.post(`/users/create`, params);
  }

  static async update(params: User): Promise<void> {
    return await axiosInstance.post(`/users/update`, params);
  }
}

const getPeopleListMock = async () => {
  await delay(500);
  return {
    success: true,
    data: {
      count: 1076,
      items: [
        {
          id: '312000',
          uuid: 'RDNQXLCM',
          surname: 'Ммму',
          name: 'Мукакуа',
          midname: 'Кумукм',
          birthdate: '1990-10-10T00:00:00.000+03:00',
          email: 'ergty@gmail.com',
          phone: '78452935398',
          passport: {
            series: '3424',
            number: '248294',
            docDate: '2020-10-10T00:00:00.000+03:00',
            docOrgCode: '482-492',
            docOrg: 'мвд',
          },
          activeCreditsCount: 1,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 1,
          creditCount: 1,
          blacklisted: false,
          blocked: false,
          canBeBlocked: false,
        },
        {
          id: '311887',
          uuid: 'DZWWPYLH',
          surname: 'Ккаем',
          name: 'Каем',
          midname: null,
          birthdate: '1992-01-01T00:00:00.000+03:00',
          email: '',
          phone: '73483757546',
          passport: {
            series: '6234',
            number: '263423',
            docDate: '2021-01-10T00:00:00.000+03:00',
            docOrgCode: '354-237',
            docOrg: 'уфмс',
          },
          activeCreditsCount: 1,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 1,
          creditCount: 1,
          blacklisted: false,
          blocked: false,
          canBeBlocked: false,
        },
        {
          id: '260176',
          uuid: 'QKDFBWZH',
          surname: 'Двестидесять',
          name: 'Двестидесять',
          midname: 'Двестидесять',
          birthdate: '1971-01-01T00:00:00.000+03:00',
          email: '20010@ya.ruuu',
          phone: '79872001000',
          passport: {
            series: '2452',
            number: '234523',
            docDate: '2018-01-01T00:00:00.000+03:00',
            docOrgCode: '234-562',
            docOrg: 'мвд',
          },
          activeCreditsCount: 0,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 4,
          creditCount: 0,
          blacklisted: false,
          blocked: false,
          canBeBlocked: true,
        },
        {
          id: '311966',
          uuid: 'RLFTVCXH',
          surname: 'Пеп',
          name: 'Куеп',
          midname: 'Кеп',
          birthdate: '1990-10-10T00:00:00.000+03:00',
          email: 'fetrh@gmail.com',
          phone: '78356755754',
          passport: {
            series: '2394',
            number: '873535',
            docDate: '2020-10-10T00:00:00.000+03:00',
            docOrgCode: '853-853',
            docOrg: 'мвд',
          },
          activeCreditsCount: 0,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 1,
          creditCount: 0,
          blacklisted: false,
          blocked: false,
          canBeBlocked: false,
        },
        {
          id: '278805',
          uuid: 'DPMQNFDK',
          surname: 'Четырестатри',
          name: 'Четырестатри',
          midname: 'Четырестатри',
          birthdate: '1971-01-01T00:00:00.000+03:00',
          email: '403@ya.ruuu',
          phone: '79874030000',
          passport: {
            series: '2346',
            number: '345343',
            docDate: '2018-01-01T00:00:00.000+03:00',
            docOrgCode: '434-534',
            docOrg: 'Четырестатри',
          },
          activeCreditsCount: 1,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 1,
          creditCount: 1,
          blacklisted: false,
          blocked: false,
          canBeBlocked: false,
        },
        {
          id: '311453',
          uuid: 'QZCVJVNM',
          surname: 'Кка',
          name: 'Кка',
          midname: 'Кка',
          birthdate: '1990-10-10T00:00:00.000+03:00',
          email: 'frege@gmail.com',
          phone: '73442342423',
          passport: {
            series: '4234',
            number: '234235',
            docDate: '2020-10-10T00:00:00.000+03:00',
            docOrgCode: '234-234',
            docOrg: 'мвд',
          },
          activeCreditsCount: 1,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 1,
          creditCount: 1,
          blacklisted: false,
          blocked: false,
          canBeBlocked: false,
        },
        {
          id: '118',
          uuid: 'YWRKHRXK',
          surname: 'Кучеров',
          name: 'Никита',
          midname: null,
          birthdate: '1990-01-01T00:00:00.000+03:00',
          email: null,
          phone: null,
          passport: {
            series: '1488',
            number: '228245',
            docDate: '2010-02-02T00:00:00.000+03:00',
            docOrgCode: null,
            docOrg: null,
          },
          activeCreditsCount: 0,
          closedCreditsCount: 0,
          reallyClosedCreditsCount: 0,
          creditRequestCount: 1,
          creditCount: 0,
          blacklisted: false,
          blocked: false,
          canBeBlocked: false,
        },
      ],
    },
    error: null,
    errorMessage: null,
    code: null,
  };
};
