import { faPercent } from '@fortawesome/free-solid-svg-icons';

import { Fragment, useState } from 'react';

import { ProductTable } from 'pages/product-page/ui';

import { AddProductDialog } from 'features/add-product/ui';

import { ProductApi } from 'entities/product/Product.api';

import { ButtonPlusIcon, PageHeading, PageWrapper } from 'shared/ui';

export const CreditProductPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <PageWrapper>
        <div className="flex justify-between">
          <PageHeading
            icon={faPercent}
            title="Кредитные продукты"
            refetchQueryFilters={[ProductApi.KEY, 'getProducts']}
          />
          <div className="flex justify-end gap-2 py-2">
            <ButtonPlusIcon
              text="Добавить кредитный продукт"
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
        <ProductTable />
      </PageWrapper>
      <AddProductDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Fragment>
  );
};
