import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import type { InferType } from 'yup';

import { Button, DialogTitle, Field, Input, Label } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { MerchantApi } from 'entities/merchant';

import { onAxiosError } from 'shared/lib';
import {
  DialogSimple,
  DialogSimpleProps,
  Button as SharedButton,
} from 'shared/ui';

type AddMerchantDialogProps = {
  onClick?: () => void;
  onContinue?: () => void;
} & DialogSimpleProps;

const schema = yup.object({
  file: yup.mixed().required(),
});

export const AddMerchantCsvDialog = ({
  isOpen,
  onClose,
}: AddMerchantDialogProps) => {
  const client = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    addMerchantCSVMutation.mutate(data);
  };

  const addMerchantCSVMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) =>
      MerchantApi.createFromCSV(data),
    onSuccess: (blob) => {
      client.refetchQueries({ queryKey: [MerchantApi.KEY, 'getMerchants'] });
      toast.success('Успешно');
      onClose();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'merchant_csv_response.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },
    onError: onAxiosError,
  });

  return (
    <DialogSimple isOpen={isOpen} onClose={onClose}>
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        Добавление магазина из файла .csv
      </DialogTitle>
      <a
        className="mt-3 inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
        href="/assets/Пример_загрузки_магазинов.csv"
        download
      >
        Скачать пример
      </a>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Выбрать</Label>
          <Input
            {...register('file')}
            type="file"
            accept=".csv"
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <div className="mt-4 flex gap-2">
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={onClose}
          >
            Отмена
          </Button>
          <SharedButton
            className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
            disabled={addMerchantCSVMutation.isPending}
          >
            Добавить
          </SharedButton>
        </div>
      </form>
      <div className="mt-3 flex flex-col gap-1">
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="text-sm/6 font-medium text-rose-400">
              {message}
            </span>
          ))}
      </div>
    </DialogSimple>
  );
};
