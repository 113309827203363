import './styles.scss';

import { useCopyToClipboard } from 'usehooks-ts';

import type { DispatchedStateAction } from 'shared/typings';

export const CopyButton = ({
  value,
  copiedValue,
  setCopiedValue,
}: {
  value: string;
  copiedValue: string;
  setCopiedValue: DispatchedStateAction<string>;
}) => {
  const [copied, copy] = useCopyToClipboard();

  const onClick = () => {
    if (copiedValue !== value) {
      copy(value);
      setCopiedValue(value);
      return;
    }
    copy('');
  };

  return copiedValue !== copied ? (
    <span className="copy-button" onClick={onClick}>
      копировать
    </span>
  ) : (
    <span className="copy-button copy-button_copied">скопировано</span>
  );
};
