import { cx } from '@emotion/css';

import { useState } from 'react';

import { EditUserDialog } from 'features/edit-user/ui';

import type { User } from 'entities/user';
import { UserApi } from 'entities/user';

import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

export const UsersTable = () => {
  const [editUser, setEditUser] = useState<User | false>(false);

  const columns: AccessorKey<User>[] = [
    { accessorKey: 'enabled' },
    { accessorKey: 'login' },
    { accessorKey: 'roles' },
    { accessorKey: 'authorities' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="w-[50px] whitespace-nowrap px-6  py-3 text-center"
      >
        Статус
      </th>
      <th scope="col" className="whitespace-nowrap px-6 py-3  text-center ">
        Логин
      </th>
      <th scope="col" className="whitespace-nowrap px-6 py-3  text-center ">
        Роли
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 text-center">
        Вид пользователя
      </th>
    </tr>
  );

  const renderRow = (user: User) => {
    return (
      <tr
        key={user.id}
        onClick={() => setEditUser(user)}
        className="cursor-pointer border-b border-gray-700 bg-white hover:bg-brand-1/20"
      >
        <td className="px-6 py-4 text-center">
          <span
            className={cx(
              'flex-center m-auto h-[6px] w-[6px] rounded-full',
              !user.enabled ? 'bg-rose-600' : 'bg-green-600',
            )}
          />
        </td>
        <td className="px-6 py-4 text-center">{user.login}</td>
        <td className="px-6 py-4 text-center ">{user.roles.join(', ')}</td>
        <td className="px-6 py-4 text-center ">
          {user.authorities.join(', ')}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table
        queryKeys={[UserApi.KEY, 'getUsers']}
        queryFn={UserApi.getUsers}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {editUser && (
        <EditUserDialog user={editUser} onClose={() => setEditUser(false)} />
      )}
    </>
  );
};
