import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import type { InferType } from 'yup';

import { Button, DialogTitle, Field, Input, Label } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { SelectPartner } from 'features/add-partner/ui';

import type { Merchant } from 'entities/merchant';
import { MerchantApi } from 'entities/merchant';
import { SelectOrderFlow } from 'entities/merchant/ui';
import { StatusSelect } from 'entities/status-select/ui';

import { onAxiosError } from 'shared/lib';
import { DialogSimple, Button as SharedButton } from 'shared/ui';

type EditMerchantDialogProps = {
  merchant: Merchant | null;
  onClose: () => void;
};

const schema = yup.object({
  id: yup.string().optional(),
  active: yup.boolean().optional(),
  name: yup.string().trim().min(4).optional(),
  description: yup.string().trim().min(4).optional(),
  order_flow: yup.string().optional(),
  partner_id: yup.number().optional(),
  publicKey: yup.string().optional(),
  privateKey: yup.string().optional(),
});

export const EditMerchantDialog = ({
  merchant,
  onClose,
}: EditMerchantDialogProps) => {
  const client = useQueryClient();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const editMerchantMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) => MerchantApi.update(data),
    onSuccess: () => {
      client.refetchQueries({ queryKey: [MerchantApi.KEY, 'getMerchants'] });
      toast.success('Данные успешно изменены');
      onClose();
    },
    onError: onAxiosError,
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    editMerchantMutation.mutate(data);
  };

  setValue('id', merchant!.id);
  setValue('active', merchant?.active);
  merchant?.partner_id && setValue('partner_id', +merchant?.partner_id);

  return (
    <DialogSimple isOpen onClose={onClose}>
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        Редактирование магазина
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Название</Label>
          <Input
            {...register('name')}
            defaultValue={merchant!.name}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Статус</Label>
          <StatusSelect
            setValue={setValue}
            valueKey="active"
            defaultActive={!!merchant?.active}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Описание</Label>
          <Input
            {...register('description')}
            defaultValue={merchant!.description}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Тип</Label>
          <SelectOrderFlow
            setValue={setValue}
            defaultValue={merchant!.order_flow}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Партнер</Label>
          <SelectPartner
            setValue={setValue}
            valueKey="partner_id"
            defaultValue={merchant!.partner_id}
          />
        </Field>
        <div className="mt-4 flex gap-2">
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={onClose}
          >
            Отмена
          </Button>
          <SharedButton
            className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
            disabled={editMerchantMutation.isPending}
          >
            Обновить
          </SharedButton>
        </div>
      </form>
      <div className="mt-3 flex flex-col gap-1">
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="text-sm/6 font-medium text-rose-400">
              {message}
            </span>
          ))}
      </div>
    </DialogSimple>
  );
};
