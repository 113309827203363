import type { Order } from 'entities/order';

import { axiosInstance } from 'shared/api/axios.defaults';
import type { TableResponse } from 'shared/typings';

export class RequestApi {
  static readonly KEY = 'order';

  static async getRequests(): Promise<TableResponse<Order>> {
    const { data } = await axiosInstance.get(`/order-requests`);
    return data;
  }

  static async getRequest(id: string): Promise<Order> {
    const { data } = await axiosInstance.get(`/order-requests/${id}`);
    return data;
  }
}
