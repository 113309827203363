import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import type { InferType } from 'yup';

import { Button, DialogTitle, Field, Input, Label } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import type { User } from 'entities/user';
import { AUTHORITIES, ROLES, UserApi } from 'entities/user';
import { InputCheckbox } from 'entities/user/ui';

import { onAxiosError } from 'shared/lib';
import { DialogSimple, Button as SharedButton } from 'shared/ui';

type EditUserDialog = {
  user: User | null;
  onClose: () => void;
};

const schema = yup.object({
  id: yup.string().required(),
  login: yup.string().trim().min(4).required(),
  newPassword: yup.string().trim().notRequired(),
  roles: yup.array().required().default(['EMPLOYEE']),
  authorities: yup.array().required(),
  enabled: yup.boolean().default(true),
});

export const EditUserDialog = ({ onClose, user }: EditUserDialog) => {
  const client = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const editUserMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) => UserApi.update(data),
    onSuccess: () => {
      client.refetchQueries({ queryKey: [UserApi.KEY, 'getUsers'] });
      toast.success('Пользователь изменен');
      onClose();
    },
    onError: onAxiosError,
  });

  const onSubmit = (data: InferType<typeof schema>) => {
    editUserMutation.mutate(data);
  };

  setValue('id', user!.id as string);

  return (
    <DialogSimple isOpen onClose={console.log}>
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        Редактирование пользователя
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Логин</Label>
          <Input
            defaultValue={user?.login}
            {...register('login')}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Новый пароль
          </Label>
          <Input
            {...register('newPassword')}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Роли</Label>
          <InputCheckbox
            items={Object.keys(AUTHORITIES)
              .filter((key) => isNaN(Number(key)))
              .map((key) => ({ id: key, label: key }))}
            defaultValues={user?.authorities}
            onChange={(items) => {
              setValue('authorities', items);
            }}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Роли</Label>
          <InputCheckbox
            items={Object.keys(ROLES)
              .filter((key) => isNaN(Number(key)))
              .map((key) => ({ id: key, label: key }))}
            defaultValues={user?.roles}
            onChange={(items) => {
              setValue('roles', items);
            }}
          />
        </Field>
        <div className="mt-4 flex gap-2">
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={onClose}
          >
            Отмена
          </Button>
          <SharedButton
            className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
            disabled={editUserMutation.isPending}
          >
            Обновить
          </SharedButton>
        </div>
      </form>
      <div className="mt-3 flex flex-col gap-1">
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="text-sm/6 font-medium text-rose-400">
              {message}
            </span>
          ))}
      </div>
    </DialogSimple>
  );
};
